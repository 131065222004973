import "../../assets/css/Congratulations.css";

const Congratulations = () => {
  return (
    <div className="congrats-container">
      <div className="congrats-layout">
        <div>
          <h1 className="congrats-header">Congratulations!</h1>
          <div className="text-white congrats-paragraphs">
            <p>
              You have Completed Enrollment. Please be sure to keep your
              employee card in your digital wallet and monitor your email and
              SMS for additional digital cards to add to your wallet (based on
              coverages you have enrolled in for the new plan year)!
            </p>
            <p>
              Remember that these cards are always in your digital wallet. Click
              the three dots for Pass Details and access to the back of the card
              with links to important contacts and resources about your
              benefits.
            </p>
            <p>
              Additional changes can be made by using the SmartBen Portal or
              contacting a Benefits counselor.
            </p>
          </div>
        </div>
        <img
          className="image-congrats"
          src="../../assets/img/examples.png"
          alt="congrats"
        />
      </div>
    </div>
  );
};

export default Congratulations;
