import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Divider from "../../assets/utilities/Divider";
import VerticalStepperComponent from "./VerticalStepperComponent";
import Modal from "./Modal";
import "../../assets/css/Global.css";
import "../../assets/css/VerticalStepperComponent.css";
import { useNavigate } from "react-router-dom";
import * as pdfjsLib from "pdfjs-dist";

const Welcome = () => {
  const { passId } = useParams(); // Use useParams to get passId from URL
  const [memberDetails, setMemberDetails] = useState(null);
  const [metadata, setMetadata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Initially set to false to show modal after loading
  const [modalContent, setModalContent] = useState({ type: "disclaimer" });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [entityId, setEntityId] = useState("");
  const [firstName, setFirstName] = useState(""); // New state for first name
  const [token, setToken] = useState(null); // New state for token
  const [pdfDocument, setPdfDocument] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const authenticate = async () => {
      try {
        // const response = await fetch(
        //   "https://oauth.cxserver.app/api/v1/client/create",
        //   {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({
        //       client_name: "Biandre test 5",
        //       grants: [
        //         "authorization_code",
        //         "refresh_token",
        //         "client_credentials",
        //       ],
        //       entity_id: "a1111111-1111-1111-1111-111111111111",
        //       parent_id: "c1111111-1111-1111-1111-111111111111",
        //       redirect_uri: "https://connectxdigital.com",
        //     }),
        //   }
        // );

        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }

        // const data = await response.json();
        //
        // GLOBAL USER
        //
        const clientId = "2f07efe0-5d34-44fc-9a2e-93d61510d463";
        const clientSecret = "693297222160db64c9611f44643441fd";

        const tokenResponse = await fetch(
          "https://oauth.cxserver.app/api/v1/oauth/token",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              grant_type: "client_credentials",
              client_id: clientId,
              client_secret: clientSecret,
            }),
          }
        );

        if (!tokenResponse.ok) {
          throw new Error("Network response was not ok");
        }

        const tokenData = await tokenResponse.json();
        const token = tokenData.access_token.token;

        setToken(token);
        setIsAuthenticated(true);
      } catch (error) {
        console.error("Error authenticating:", error);
      }
    };

    authenticate();
  }, []);

  useEffect(() => {
    if (!passId) {
      setLoading(false);
      navigate("/form");
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://member.cxserver.app/api/v1/members/${passId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new TypeError("Received non-JSON response");
        }

        const text = await response.text();
        const data = text ? JSON.parse(text) : {};

        setMemberDetails(data.memberDetails);
        setFirstName(data.memberDetails.first_name);
        setMetadata(data.metadata);
        setEntityId(data.memberDetails.entity_id);

        const benefitsPdfMetadata = data.metadata.find(
          (item) => item.key === "benefitsPdf"
        );

        const pdfStream = await fetch(
          `https://ws.cxserver.app/api/microsite/v1/createPdf`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              entity_id: data.memberDetails.entity_id,
              first_name: data.memberDetails.first_name,
              last_name: data.memberDetails.last_name,
              template_name: "HARLAN_FOODS",
              userType: "member",
            }),
          }
        );

        const reader = pdfStream.body.getReader();
        const chunks = [];
        let done = false;

        while (!done) {
          const { value, done: streamDone } = await reader.read();
          done = streamDone;
          if (value) {
            chunks.push(value);
          }
        }

        // Concatenate all chunks into a single Uint8Array
        const pdfData = new Uint8Array(
          chunks.reduce((acc, val) => acc.concat(Array.from(val)), [])
        );

        // Load the PDF document
        const loadingTask = pdfjsLib.getDocument({ data: pdfData });
        const pdfDocument = await loadingTask.promise;

        setPdfDocument(pdfDocument);

        const disclaimerAcknowledged = data.metadata.find(
          (item) => item.key === "disclamerAcknowledged"
        );

        if (
          !disclaimerAcknowledged ||
          disclaimerAcknowledged.value !== "true"
        ) {
          setIsModalOpen(true);
        }
      } catch (err) {
        setError(err);
        setModalContent({ type: "info", message: err.message });
        setIsModalOpen(true);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchData();
    }
  }, [passId, token, navigate]);

  const handleAcknowledge = async (acknowledgmentData) => {
    try {
      const response = await fetch(
        `https://member.cxserver.app/api/v1/metadata/put`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(acknowledgmentData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      Cookies.set("disclaimerAcknowledged", "true", { expires: 365 }); // 1 year expiration
      setIsModalOpen(false); // Close the modal after acknowledgment
    } catch (error) {
      console.error("Error acknowledging disclaimer:", error);
    }
  };

  const handleCompleteStep2 = async (selection) => {
    try {
      const selectionData = {
        memberId: entityId,
        key: `step2_selection`,
        value: JSON.stringify(selection),
      };

      const response = await fetch(
        `https://member.cxserver.app/api/v1/metadata/put`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(selectionData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      await response.json();
      // if (selectionData.)
      setModalContent({ type: "thankYou" });
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error submitting step 2 selection:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div className="welcome-page">
        <div className="blue-div">
          <p className="paragraph-text">{error.message}</p>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          content={modalContent}
          entityId={entityId} // Pass the entityId to Modal
          firstName={firstName}
          onAcknowledge={handleAcknowledge} // Pass the handleAcknowledge function
        />
      </div>
    );
  }

  return (
    <div>
      {" "}
      {/* Apply blur class conditionally */}
      <div className="blue-div">
        <Divider />
        <VerticalStepperComponent
          memberDetails={memberDetails}
          metadata={metadata}
          pdfDocument={pdfDocument}
          onCompleteStep2={handleCompleteStep2}
          onAcknowledge={handleAcknowledge} // Pass the handleAcknowledge function
          isAuthenticated={isAuthenticated}
        />
      </div>
      {isModalOpen && (
        <div className="modal-overlay">
          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            content={modalContent}
            entityId={entityId}
            firstName={firstName}
            onAcknowledge={handleAcknowledge} // Pass the handleAcknowledge function
          />
        </div>
      )}
    </div>
  );
};

export default Welcome;
