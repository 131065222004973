import React, { useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css"; // Make sure the rc-slider CSS is correctly imported
import "../../assets/css/Step2ContentMobile.css";

const Step2ContentMobile = ({ handleNext, handlePrevious, onComplete }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [fsaAmount, setFsaAmount] = useState(0);
  const [dependentFsaAmount, setDependentFsaAmount] = useState(0);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSliderChange = (value, type) => {
    if (type === "fsa") {
      setFsaAmount(value);
    } else if (type === "dependentFsa") {
      setDependentFsaAmount(value);
    }
  };

  const handleSubmit = () => {
    if (selectedOption) {
      onComplete({
        action: "Selected Option",
        option: selectedOption,
        fsaAmount,
        dependentFsaAmount,
        timestamp: new Date().toISOString(),
      });
      handleNext();
    } else {
      alert("Please select an option");
    }
  };

  const handleScheduleMeeting = () => {
    onComplete({
      action: "Selected Option",
      option: selectedOption,
      timestamp: new Date().toISOString(),
    });
    window.open("https://calendly.com/harlan-foods", "_blank");
  };

  const handleGoToSmartBen = () => {
    onComplete({
      action: "Selected Option",
      option: selectedOption,
      timestamp: new Date().toISOString(),
    });
    window.open("https://harlanbakeries.smartben.net", "_blank");
  };

  //   const totalCost = fsaAmount + dependentFsaAmount;
  //   const biWeeklyCost = totalCost / 26;
  //   const weeklyCost = totalCost / 52;

  return (
    <div className="mobile-container">
      <p>
        Make Your 10/1/24 Benefits Elections. Select from one of the three
        choices below.
      </p>
      <div className="option-container">
        <label>
          <input
            type="radio"
            name="benefitOption"
            value="option1"
            checked={selectedOption === "option1"}
            onChange={handleOptionChange}
          />
          Option 1: No changes to my current benefit elections, and I do not
          wish to contribute to a Flexible Spending Account (FSA).
        </label>
      </div>
      <div className="option-container">
        <label>
          <input
            type="radio"
            name="benefitOption"
            value="option2"
            checked={selectedOption === "option2"}
            onChange={handleOptionChange}
          />
          Option 2: No changes to my current benefit elections, and I would like
          to contribute to a Healthcare FSA and/or Dependent Care FSA.
        </label>
        {selectedOption === "option2" && (
          <div className="slider-container">
            <div className="slider-group">
              <label>Healthcare FSA</label>
              <Slider
                min={0}
                max={3200}
                step={100}
                value={fsaAmount}
                onChange={(value) => handleSliderChange(value, "fsa")}
              />
              <div className="slider-values">
                <span>Min: $0</span>
                <span>Max: $3200</span>
              </div>
              <div className="slider-results">
                <p>Annual Amount: ${fsaAmount}</p>
                <div className="small-text-container">
                  <p className="small-text">
                    Bi-Weekly Amount: ${(fsaAmount / 26).toFixed(2)}
                  </p>
                  <p className="small-text">
                    Weekly Amount: ${(fsaAmount / 52).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
            <div className="slider-group">
              <label>Dependent Care FSA</label>
              <Slider
                min={0}
                max={5000}
                step={100}
                value={dependentFsaAmount}
                onChange={(value) => handleSliderChange(value, "dependentFsa")}
              />
              <div className="slider-values">
                <span>Min: $0</span>
                <span>Max: $5000</span>
              </div>
              <div className="slider-results">
                <p>Annual Amount: ${dependentFsaAmount}</p>
                <div className="small-text-container">
                  <p className="small-text">
                    Bi-Weekly Amount: ${(dependentFsaAmount / 26).toFixed(2)}
                  </p>
                  <p className="small-text">
                    Weekly Amount: ${(dependentFsaAmount / 52).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="option-container">
        <label>
          <input
            type="radio"
            name="benefitOption"
            value="option3"
            checked={selectedOption === "option3"}
            onChange={handleOptionChange}
          />
          Option 3: Adjust my Benefit elections for next plan year. If you are a
          new employee and have not completed enrollment yet, select this
          option.
        </label>
      </div>
      {selectedOption === "option3" ? (
        <div className="button-group">
          <button className="option-button" onClick={handleScheduleMeeting}>
            Schedule a Meeting
          </button>
          <button className="option-button" onClick={handleGoToSmartBen}>
            Go to SmartBen
          </button>
        </div>
      ) : (
        selectedOption && (
          <div className="button-group">
            <button className="option-button" onClick={handleSubmit}>
              Acknowledge Selection
            </button>
          </div>
        )
      )}
    </div>
  );
};

export default Step2ContentMobile;
