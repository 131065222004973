// src/components/components-home/ActivationForm.js
import React, { useState } from "react";
import Modal from "./Modal";
import "../../assets/css/Global.css";
import "../../assets/css/VerticalStepperComponent.css";
import "./ActivationForm.css"; // Import the new CSS file for styling
import { useNavigate } from "react-router-dom";

const ActivationForm = () => {
  const [last4SSN, setLast4SSN] = useState("");
  const [dob, setDob] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  const handleWalletCard = async () => {
    if (!last4SSN || !dob) {
      setError("Please fill in both fields.");
      return;
    }

    // Clear previous messages
    setError(null);
    setMessage(null);
    setIsModalOpen(false);
    setModalContent("");

    try {
      const formattedDob = formatDate(dob);

      const response = await fetch(
        "https://member.cxserver.app/api/v1/metadata/cardUrl",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            dob: formattedDob,
            ssn: last4SSN,
          },
        }
      );

      if (!response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const result = await response.json();
          setModalContent(
            result.message || "An error occurred. Please try again later."
          );
        } else {
          setModalContent("An error occurred. Please try again later.");
        }
        setIsModalOpen(true);
        return;
      }

      const result = await response.json();
      window.location.href = `https://cxd-customers.s3.us-east-2.amazonaws.com/${result.link}`;
    } catch (err) {
      setModalContent("An error occurred. Please try again later.");
      setIsModalOpen(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!last4SSN || !dob) {
      setError("Please fill in both fields.");
      return;
    }

    // Clear previous messages
    setError(null);
    setMessage(null);
    setIsModalOpen(false);
    setModalContent("");

    try {
      const formattedDob = formatDate(dob);

      const response = await fetch(
        "https://member.cxserver.app/api/v1/members/getMemberCreds",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            userType: "admin",
            dob: formattedDob,
            ssn: last4SSN,
          },
        }
      );

      if (!response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const result = await response.json();
          if (response.status === 404) {
            setModalContent(
              "Record not found. Please call H at (123) 456-7890."
            );
          } else {
            setModalContent(
              result.message || "An error occurred. Please try again later."
            );
          }
        } else {
          setModalContent("An error occurred. Please try again later.");
        }
        setIsModalOpen(true);
        return;
      }

      const result = await response.json();
      setMessage(result.message || "Form submitted successfully");
      navigate(`/Harlan/${result.memberCreds.pass_id}`);
    } catch (err) {
      setModalContent("An error occurred. Please try again later.");
      setIsModalOpen(true);
    }
  };

  return (
    <div className="activation-form-page">
      <div className="activation-form-container">
        <h3 className="paragraph-text">
          Welcome to Your 2024 - 2025 Health Benefits
        </h3>
        <p>
          Please enter your last 4 SSN (or TIN) and date of birth to proceed.
        </p>
        <form onSubmit={handleSubmit} className="activation-form">
          <div className="form-group">
            <label htmlFor="last4SSN">Last 4 SSN (or TIN):</label>
            <input
              type="text"
              id="last4SSN"
              value={last4SSN}
              onChange={(e) => setLast4SSN(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="dob">Date of Birth:</label>
            <input
              type="date"
              id="dob"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </div>
          <div className="action-container">
            <button
              type="button"
              onClick={handleWalletCard}
              className="submit-button"
            >
              Click here first to add your Benefits card.
            </button>
            <button type="submit" className="submit-button">
              Alternate entry to Enrollment portal.
            </button>
          </div>
        </form>
        {error && <p className="error">{error}</p>}
        {message && <p className="message">{message}</p>}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={{ type: "info", message: modalContent }}
      />
    </div>
  );
};

export default ActivationForm;
