import React, { useState, useEffect, useRef, useCallback } from "react";
import Divider from "../../assets/utilities/Divider";
import Step2Content from "./Step2"; // Adjust the path if necessary
import Step2ContentMobile from "./Step2ContentMobile"; // Import the mobile-specific component
import { useMediaQuery } from "react-responsive";
import "../../assets/css/VerticalStepperComponent.css";
import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const VerticalStepperComponent = ({
  memberDetails,
  metadata,
  pdfDocument,
  onCompleteStep2,
  onAcknowledge,
  isAuthenticated,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [currentStep, setCurrentStep] = useState(0);
  const [acknowledged, setAcknowledged] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [selectionMetadata, setSelectionMetadata] = useState(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const acknowledgmentMetadata = metadata.find(
      (item) => item.key === "Step1Acknowledged"
    );
    if (acknowledgmentMetadata && acknowledgmentMetadata.value === "true") {
      setAcknowledged(true);
      setCurrentStep(1);

      const selectionMetadata = metadata.find(
        (item) => item.key === "step2_selection"
      );

      if (selectionMetadata) {
        const parsedMetadata = JSON.parse(selectionMetadata.value);
        setSelectionMetadata(parsedMetadata);
      }
    }
  }, [metadata]);

  const renderPage = useCallback(
    async (pageNum) => {
      if (!canvasRef.current || !pdfDocument) {
        console.error("Canvas element is not available");
        return;
      }

      const pdf = pdfDocument;
      setNumPages(pdf.numPages);
      const page = await pdf.getPage(pageNum);

      const scale = window.devicePixelRatio || 1;
      const viewport = page.getViewport({ scale: scale });
      const canvas = canvasRef.current;

      if (!canvas) {
        console.error("Canvas element is not available");
        return;
      }

      const context = canvas.getContext("2d");

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      const renderTask = page.render(renderContext);
      await renderTask.promise;

      // Resize canvas to container size
      const container = canvas.parentElement;
      const containerWidth = container.clientWidth;
      const containerHeight = (canvas.height * containerWidth) / canvas.width;
      canvas.style.width = `${containerWidth}px` - 50;
      canvas.style.height = `${containerHeight}px`;
    },
    [pdfDocument]
  );

  useEffect(() => {
    if (pdfDocument) {
      renderPage(pageNumber);
    }
  }, [pdfDocument, pageNumber, renderPage]);

  const goToPreviousPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  };

  const handleNext = () => {
    if (currentStep === 0 && !acknowledged) {
      const acknowledgmentData = {
        memberId: memberDetails?.entity_id,
        key: "Step1Acknowledged",
        value: "true",
      };

      onAcknowledge(acknowledgmentData); // Pass data to parent for API call
      setAcknowledged(true);
      setCurrentStep(currentStep + 1);
    } else {
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleStep2Selection = (selection) => {
    onCompleteStep2(selection); // Pass data to parent for API call
  };

  const steps = [
    {
      title: "Step 1 - Review Your Current Benefits",
      content: (
        <div>
          <p className="step-text">
            Review and Acknowledge receipt of your current Benefit Confirmation
            Statement. Payroll deductions listed are for the{" "}
            <strong>NEW</strong> plan year.
          </p>
          <div className="step-content-container">
            <div className="pdf-container">
              <canvas ref={canvasRef} className="pdf-canvas"></canvas>
              <div className="pdf-navigation">
                <button onClick={goToPreviousPage} disabled={pageNumber <= 1}>
                  Previous Page
                </button>
                <button
                  onClick={goToNextPage}
                  disabled={pageNumber >= numPages}
                >
                  Next Page
                </button>
              </div>
            </div>
            <button
              className="option-button"
              onClick={async () => {
                const pdfBytes = await pdfDocument.getData();
                const blob = new Blob([pdfBytes], {
                  type: "application/pdf",
                });
                const pdfUrl = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = pdfUrl;
                a.target = "_blank";
                a.download = "Benefit_Confirmation_Statement.pdf";
                a.click();
              }}
            >
              Download Benefit Confirmation Statement
            </button>
            {acknowledged ? (
              <p className="acknowledged-text">
                You have already acknowledged receipt of your Benefit
                Confirmation Statement.
              </p>
            ) : (
              <div className="button-group">
                <button
                  className="option-button"
                  onClick={handleNext}
                  disabled={acknowledged}
                >
                  I Acknowledge
                </button>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Step 2 - 2024-2025 Benefit Selections",
      content: (handleNext, handlePrevious) =>
        isMobile ? (
          <Step2ContentMobile
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            onComplete={handleStep2Selection}
          />
        ) : (
          <Step2Content
            selectedData={selectionMetadata}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            onComplete={handleStep2Selection}
          />
        ),
    },
  ];

  return (
    <div
      className={`vertical-stepper-container ${
        !isAuthenticated ? "blurred" : ""
      }`}
    >
      <p className="paragraph-text">
        Hello, {memberDetails?.first_name || "Guest"}! Welcome to Harlan's Open
        Enrollment Benefits Portal. Please step through this simple process to
        complete and sign off on your benefit elections for the 10/1/24 plan
        year. If you want to make changes or speak with a Benefits Counselor to
        assist with this process, you can schedule an appointment in Step 2.
      </p>
      <Divider />
      <div className="steps">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step ${index === currentStep ? "active" : ""}`}
          >
            <div className="step-title">{step.title}</div>
            <div className="step-content">
              {typeof step.content === "function"
                ? step.content(handleNext, handlePrevious)
                : step.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerticalStepperComponent;
